<template>
  <div>
    <!-- 沉浸式头部 -->
    <nav-bar :header-title="AI.activityName" backColor="#fff" :show-back="true" :header-back="newAppBack"
      @setBarHeight="setBarHeight" />
    <!-- 预告 -->
    <div v-if="AI.activityTrailer" class="marketing-preview col-start-center">
      <img :src="item" v-for="item in AI.activityTrailer.imgList" :key="item" style="width: 100%" alt="">
      <van-count-down v-if="AI.activityTrailer.previewTime" style="opacity: 0;" @finish="previewFinish"
        :time="AI.activityTrailer.previewTime" />
    </div>
    <!-- 主会场 -->
    <div v-else class="marketing-template-2023 col-start-center"
      :style="`background-color: ${AI.shopStyle.backgroundColor};`">
      <div class="background">
        <!-- 主视觉 -->
        <img :src="AI.shopStyle.headerMainImg" class="head-img" />
        <div class="top-button">
          <!-- 分享按钮 -->
          <template v-if="AI.shopStyle && AI.shopStyle.shareStatus">
            <template v-if="AI.shopStyle.shareIconImg">
              <img class="icon" :src="AI.shopStyle.shareIconImg" @click="showShare" alt="">
            </template>
            <template v-else>
              <div class="button f24" :style="`background: ${AI.shopStyle.shareIconColor}`" @click="showShare">分享</div>
            </template>
          </template>
          <!-- 规则按钮图标 -->
          <template v-if="AI.shopStyle.ruleStatus">
            <template v-if="AI.shopStyle.ruleIconImg">
              <img class="icon" :src="AI.shopStyle.ruleIconImg" @click="openRule(true)" alt="">
            </template>
            <template v-else>
              <div class="button f24" :style="`background: ${AI.shopStyle.ruleIconColor}`" @click="openRule(true)">规则
              </div>
            </template>
          </template>
          <img class="coupon" v-if="hasCoupon" @click="showCouponFun" :src="baseImgUrl + 'coupon.png'">
        </div>
        <!-- 右上角的悬浮按钮 -->
        <img v-if="AI.shopStyle.floatStatus" @click="toPath(AI.shopStyle.floatLink)" :src="AI.shopStyle.floatImg" alt=""
          class="float-icon">
        <!-- 倒计时 -->
        <count-down class="count-down" v-if="AI.shopStyle.headerCountdown" :timeObj="AI" :status="activityStatus"
          @timeFinish="timeFinish"></count-down>
      </div>
      <div class="banner-block row-between-center">
        <img @click="getCoupon" :src="baseImgUrl + 'c-banner.png'" alt="">
      </div>
      <!-- banner -->
      <div class="banner-block row-between-center" v-for="banner, index in AI.bannerList" :key="index">
        <img @click="toPath(item.url)" :src="item.imgUrl" alt=""
          :class="['small-banner', banner.bannerList.length > 1 ? '' : 'flex-auto']" v-for="item in banner.bannerList"
          :key="item.imgUrl">
      </div>
      <!-- 带货人 信息 -->
      <div v-if="AI.shopStyle.inviterStatus && (inviteUserInfo.shareTeacherId || inviteUserInfo.shareUserId)"
        class="invite-block row-start-center f22">
        <div class="invite-head"><img :src="`${inviteUserInfo.shareUserHeadImg}`" alt="" /></div>
        <div class="invite-name ellipsis" :style="`color: ${AI.shopStyle.subfieldNameColor};`">
          <span>「{{ inviteUserInfo.shareTitle }}」</span> 邀请你参与{{ AI.activityName }}
        </div>
      </div>
      <!-- 分区标题图 -->
      <img v-if="AI.shopStyle.partitionTitleImg" :src="AI.shopStyle.partitionTitleImg" alt="" class="nav-title">
      <!-- 商品导航 -->
      <van-sticky v-if="AI.partitions.subfieldsInfos && AI.partitions.subfieldsInfos.length > 1" style="width: 100%"
        :offset-top="barHeight" @scroll="stickyFun">
        <div class="nav" ref="nav" :style="`background-color: ${AI.shopStyle.backgroundColor};`">
          <div class="nav-top row-between-center flex-wrap">
            <div :class="['tm', 'col-center-start',
              (AI.partitions.subfieldsInfos.length % 3 === 0 || (AI.partitions.subfieldsInfos.length === 5 && index > 1)) ? 'small' : '',
              tabIndex === index ? 'active' : '']"
              :style="`background: linear-gradient(180deg, ${tabIndex === index ? AI.shopStyle.clickSubfieldMenuStyle.bgTopColor : AI.shopStyle.defaultSubfieldMenuStyle.bgTopColor} 0%, ${tabIndex === index ? AI.shopStyle.clickSubfieldMenuStyle.bgBottomColor : AI.shopStyle.defaultSubfieldMenuStyle.bgBottomColor} 100%);color: ${tabIndex === index ? AI.shopStyle.clickSubfieldMenuStyle.textColor : AI.shopStyle.defaultSubfieldMenuStyle.textColor}`"
              v-for="item, index in AI.partitions.subfieldsInfos" :key="index" @click="setTabIndex(index)">
              <div class="fw6 f28">{{ item.menuName }}</div>
              <div class="ii f24">{{ item.light }}</div>
            </div>
          </div>
        </div>
      </van-sticky>

      <div class="template-content col-start-center">
        <div v-if="AI.partitions.subfieldsInfos && AI.partitions.subfieldsInfos.length > 1"
          class="title-line row-between-center f20" :style="`color: ${AI.shopStyle.subfieldNameColor};`">
          <img :src="baseImgUrl + 'title-line.png'" alt="">
          <p class="subfield-name fy f36">{{ curGoods.subfieldName }}</p>
          <div class="row-start-center" @click="openRule(false)">
            <span class="ss">规则</span>
            <img class="triangle" :src="baseImgUrl + 'triangle.png'" alt="">
          </div>
        </div>
        <!-- 分栏 菜单  -->
        <van-sticky v-if="curGoods.showGroupMenu && curGoods.groupInfos.length > 1" :offset-top="(barHeight + navHeight)">
          <div class="group-bar row-start-center" :style="`background-color: ${AI.shopStyle.backgroundColor};`">
            <div class="group-bg">
              <template v-for="(item, index) in curGoods.groupInfos">
                <div v-show="item.show"
                  :class="['item', 'row-center-center', 'f28', 'fw6', curGoods.groupInfos.length > 5 ? 'flex-none' : 'flex-auto', groupIndex == index ? 'active' : '']"
                  @click="toGroup(`group${item.id}`, index)" :ref="`item${item.id}`" :key="index">
                  {{ item.groupMenuName }}
                </div>
              </template>
            </div>
          </div>
        </van-sticky>

        <div class="good-block">
          <!-- 分组上部广告位 -->
          <div class="ad-group col-start-center"
            v-if="curGoods.promotionalInfo.status && curGoods.promotionalInfo.topAds">
            <img :src="item.imgUrl" @click="toAppPath(item.url)" v-for="item in curGoods.promotionalInfo.topAds"
              :key="item.imgUrl" alt="" class="ad-item">
          </div>
          <div v-for="group in curGoods.groupInfos" :key="group.id" :ref="`group${group.id}`">
            <div v-if="group.goodsInfos.length && group.show"
              :class="['good-group', 'flex-wrap', (curGoods.groupInfos.length > 1 && group.groupName) ? 'has-title' : '', group.onlyImgMode ? 'only-img row-start-start' : 'row-between-start']"
              :key="group.id">

              <!-- 分组的标题 -->
              <div v-if="curGoods.groupInfos.length > 1" class="group-name f30 fw6 row-start-center"
                :style="`background-image: url(${AI.shopStyle.groupTitleImg});`">{{ group.groupName }}</div>
              <!-- 一行一个 -->
              <template v-if="group.listStyle === '1' && !group.onlyImgMode">
                <style-one-card :class="['good' + good.id, good.id == linkGoods ? 'hand' : '']"
                  v-show="showWithCrow(good)" :cardObj="good" :shopStyle="AI.shopStyle" :status="goodsStatusMap[good.id]"
                  :priceStatus="goodsPriceStatusMap[good.id]" :activityStatus="activityStatus"
                  v-for="good in group.goodsInfos" :key="good.id" @buyCard="buyCard(good)"
                  :isMini="isMini"></style-one-card>
              </template>
              <template v-if="group.listStyle === '1' && group.onlyImgMode">
                <img v-show="showWithCrow(good)" class="one-img"
                  :src="goodsStatusMap[good.id] === 'NOT_STARTED' ? good.cardImgIneffective : good.cardImgEffective"
                  v-for="good in group.goodsInfos" :key="good.id" @click="buyCard(good)" alt="">
              </template>

              <!-- 一行俩 -->
              <template v-if="group.listStyle === '2' && !group.onlyImgMode">
                <style-two-card :class="'good' + good.id" v-show="showWithCrow(good)" :cardObj="good"
                  :shopStyle="AI.shopStyle" :status="goodsStatusMap[good.id]" :priceStatus="goodsPriceStatusMap[good.id]"
                  :activityStatus="activityStatus" v-for="good in group.goodsInfos" :key="good.id"
                  @buyCard="buyCard(good)"></style-two-card>
              </template>
              <template v-if="group.listStyle === '2' && group.onlyImgMode">
                <img v-show="showWithCrow(good)" class="two-img flex-none"
                  :src="goodsStatusMap[good.id] === 'NOT_STARTED' ? good.cardImgIneffective : good.cardImgEffective"
                  v-for="good in group.goodsInfos" :key="good.id" @click="buyCard(good)" alt="">
              </template>
              <!-- 一行仨 -->
              <template v-if="group.listStyle === '3' && !group.onlyImgMode">
                <style-three-card :class="'good' + good.id" v-show="showWithCrow(good)" :cardObj="good"
                  :shopStyle="AI.shopStyle" :status="goodsStatusMap[good.id]" :priceStatus="goodsPriceStatusMap[good.id]"
                  :activityStatus="activityStatus" v-for="good in group.goodsInfos" :key="good.id"
                  @buyCard="buyCard(good)"></style-three-card>
              </template>
              <template v-if="group.listStyle === '3' && group.onlyImgMode">
                <img v-show="showWithCrow(good)" class="three-img flex-none"
                  :src="goodsStatusMap[good.id] === 'NOT_STARTED' ? good.cardImgIneffective : good.cardImgEffective"
                  v-for="good in group.goodsInfos" :key="good.id" @click="buyCard(good)" alt="">
              </template>
            </div>
          </div>

          <!-- 分组下部广告位 -->
          <div class="ad-group col-start-center"
            v-if="curGoods.promotionalInfo.status && curGoods.promotionalInfo.bottomAds">
            <img :src="item.imgUrl" @click="toAppPath(item.url)" v-for="item in curGoods.promotionalInfo.bottomAds"
              :key="item.imgUrl" alt="" class="ad-item">
          </div>
        </div>
        <div class="end f22">已经滑到底部啦</div>
      </div>
      <!-- 规则 弹窗 -->
      <van-popup v-model="isRuleShow" position="bottom" safe-area-inset-bottom class="pop-rule">
        <div class="pop-rule-title f32 fw6"
          :style="`background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, ${hexToRgba(AI.shopStyle.backgroundColor, 0.5)} 100%);`">
          活动规则
          <van-icon name="cross" class="pop-rule-close f28" color="#fff" @click="isRuleShow = false" />
        </div>
        <div class="pop-rule-content f26">
          {{ curRule }}
        </div>
      </van-popup>

      <template v-if="inviteUserInfo.shareTitle">
        <!-- 新人评价完推荐、签到推荐 的商品 -->
        <sign-in-recommended v-if="isSignInRecommended" :inviteUserInfo="inviteUserInfo" @buyCard="buyCard"
          @close="recommendedClose"></sign-in-recommended>
        <!-- 别人推荐的商品 -->
        <recommended-goods v-else :inviteUserInfo="inviteUserInfo" @buyCard="buyCard"
          @close="recommendedClose"></recommended-goods>
      </template>

      <!-- 规格选择 弹窗 -->
      <van-popup v-model="isItemSelectShow" @click-overlay="close" position="bottom" safe-area-inset-bottom
        class="pop-item">
        <div class="buy-title f32 fw6">
          确认下单
          <van-icon name="cross" class="buy-close f36" @click="close" />
        </div>
        <div class="cbox" v-if="isItemSelectShow">
          <div class="f28 fw6">购买商品</div>
          <div class="goods-line">
            <div class="goods-img">
              <img :src="buyGoods.goodsImg" />
            </div>
            <div class="goods-info">
              <div class="goods-name f34 fw6">{{ buyGoods.goodsName }}</div>
              <div class="time f22" v-if="buyGoods.hint">{{ buyGoods.hint }}</div>
              <div class="labels row-start-center" v-if="buyGoods.productTags.length">
                <div class="label row-center-center f20 fw6" v-for="item, index in buyGoods.productTags" :key="index">{{
                  item }}</div>
              </div>
              <div class="price-line row-start-end">
                <span class="dl f22 fw6">¥</span>
                <span class="price f32 fw6">{{ itemIndex === undefined ? buyGoods.salePrice :
                  buyGoods.itemInfos[itemIndex].salePrice }}</span>
                <span class="ori-price f20">¥{{ itemIndex === undefined ? buyGoods.originPrice :
                  buyGoods.itemInfos[itemIndex].originPrice }}</span>
              </div>
            </div>
          </div>
          <template v-if="itemList.length > 1">
            <div class="f28 fw6">{{ buyGoods.goodsItemExp }}</div>
            <div class="buy-tips f24">{{ buyGoods.notice }}</div>
            <div class="city-list">
              <div v-for="(item, index) in itemList" :key="index"
                :class="['city-model', 'row-between-center', 'fw6', itemIndex === index ? 'active' : '', itemStatusMap[item.id] === 'CAN_BUY' ? '' : 'disabled']"
                @click="chooseItem(index, itemStatusMap[item.id], item)">
                <p>{{ item.itemName }}&nbsp;&nbsp;<span v-if="itemPriceStatusMap[item.id].showAfterVoucher"
                    class="red f22 fw6">券后价</span> <span class="red f24 fw6">¥</span><span class="red">{{
                      itemPriceStatusMap[item.id].discountPrice || item.salePrice }}</span></p>
                <div v-if="itemStatusMap[item.id] === 'CAN_BUY'" class="checkbox"></div>
                <img v-else src="https://img.chaolu.com.cn/ACT/marketing/soldout.png" alt="" class="has-buy">
              </div>
            </div>
          </template>
          <add-address v-if="buyGoods.goodsType === 22" @change="addressChange" color="#FFDE00"></add-address>
        </div>
        <div
          :class="['next', 'fw6', (itemIndex !== undefined || (buyGoods.goodsType === 22 && buyGoods.itemInfos.length === 1)) ? '' : 'grey']"
          @click="next">立即购买 {{ itemIndex === undefined ? '' :
            `￥${(itemPriceStatusMap[buyGoods.itemInfos[itemIndex].id] &&
              itemPriceStatusMap[buyGoods.itemInfos[itemIndex].id].discountPrice) || buyGoods.itemInfos[itemIndex].salePrice}`
          }}</div>
      </van-popup>
      <!-- 规格选择 确认 弹窗 -->
      <van-popup v-model="isItemConfirmShow" position="center" class="pop-sure">
        <div class="sure-title f36 fw6">提示</div>
        <div class="sure-content f24">您当前购买的{{ curGoods.menuName }}为<span v-if="itemIndex != undefined">{{
          itemList[itemIndex].itemName }}</span>，请确认后再付款！</div>
        <div class="sure-sub f32 fw6" @click="nextConfirm">我知道了</div>
      </van-popup>

      <payPopupCard :showPayPopup.sync="showPayPopup" :pay-data="payData" :pay-url="payUrl"></payPopupCard>

      <!-- 海报 组件 -->
      <common-share :value="isShareShow" :shareParams="shareParams" @close="isShareShow = false"
        @share-success="isShareShow = false" :shareItem="['minifriend', 'postermessage', 'save']"
        :miniSharePoster="appTypeStr === 'mini'">
        <template slot="createDom">
          <div class="canvascss">
            <img :src="AI.shareImg" class="canvascss_bg" />
            <div class="canvascss_left">
              <img :src="userDetail.headImg" />
            </div>
            <div class="canvascss_name f26 fw6 ellipsis" :style="`color: ${AI.nickNameHex};`">
              {{ userDetail.nickName }}
            </div>
            <div class="canvascss_right">
              <img v-if="userDetail.qrCode" :src="`data:image/png;base64,${userDetail.qrCode}`" />
            </div>
          </div>
        </template>
      </common-share>

      <!-- 门店选择 -->
      <equity-venue :current-city-id="buyItem.extendInfo && buyItem.extendInfo.applyCityIds"
        :popShow.sync="venueSelectShow" :can-use-venue="buyItem.extendInfo && buyItem.extendInfo.applyVenueIds"
        @popfun="choseStorePop" @closefun="closeStorePop" :chose-item="{ areaId: '', venueId: [] }">
      </equity-venue>
      <!-- 我的优惠券 -->
      <coupon-popup ref="couponRef" @goUse="goUse" />
      <!-- 城市确认 -->
      <van-popup v-model="cityConfirmShow" position="bottom" safe-area-inset-bottom class="pop-city-c" closeable>
        <img class="icon" :src="baseImgUrl + 'tips.png'" alt="" />
        <p class="tips">
          您当前购买的产品，仅限 <span>{{ cityName }}</span> 所有门店使用，请确认后再付款！
        </p>
        <div class="btn">
          <div @click="changeCityShowFun">切换城市</div>
          <div @click="gogoBuy">确认</div>
        </div>
      </van-popup>

      <!-- 城市切换 -->
      <van-popup v-model="cityChangeShow" position="bottom" safe-area-inset-bottom class="pop-change" closeable
        @close="cityChangeClose">
        <p class="p-title">切换城市</p>
        <div class="city-list">
          <p v-for="i in cityList" :class="i.id == unQueryCityId ? 'act' : ''" @click="changeCityFun(i.id)">{{ i.name }}
          </p>
        </div>
        <div class="btn">
          <p>选择指定城市，卡仅限所选定的城市使用。</p>
          <p @click="comfrimcCityFun">确认</p>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { newAppBack, initBack, appPay, appPaySuccess, appOpenWeb } from '@/lib/appMethod'

import navBar from '@/components/nav-bar/nav-bar'
import commonShare from '@/components/commonShare'
import equityVenue from '@/components/cl-city-venue/equity-venue'

import payPopupCard from './pay-popup-card'
import styleOneCard from './components/style-one-card'
import styleTwoCard from './components/style-two-card'
import styleThreeCard from './components/style-three-card'
import countDown from './components/count-down'
import recommendedGoods from './components/recommended-goods'
import signInRecommended from './components/sign-in-recommended'
import addAddress from './components/address'
import couponPopup from './components/coupon'

import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'

import jump from './jump'

import wx from 'weixin-js-sdk'
const webUrl = `${window.location.origin}/#/superdeer-activity/anniversary-marketing-2024/index/10066`

export default {
  components: {
    navBar,
    commonShare,
    styleOneCard,
    styleTwoCard,
    styleThreeCard,
    countDown,
    recommendedGoods,
    signInRecommended,
    payPopupCard,
    addAddress,
    equityVenue,
    couponPopup
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      baseImgUrl: 'https://img.chaolu.com.cn/ACT/anniversary-marketing-2024/',
      shopId: '', // 活动id
      AI: { // 活动总信息
        activityTrailer: {}, // 预告页
        partitions: {},
        // shopStyle: { shareStatus: false }
      },
      barHeight: 0,
      navHeight: 0,

      tabIndex: 0, // 分栏的下标
      groupIndex: 0, // 分组的下标
      shareParams: {
        title: '月中健身 福利大放送',
        miniImage: 'https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_mini.png',
        path: '',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      userDetail: {}, //分享的用户信息
      inviteUserInfo: {}, // 邀请人信息

      isShareShow: false,
      isRuleShow: false,
      isItemSelectShow: false, // 规格选择弹窗
      address: {}, // 实体卡情况下 收货地址
      isItemConfirmShow: false, // 规格确认弹窗
      itemIndex: undefined, // 规格下标
      venueSelectShow: false, // 门店的弹窗

      buyGoods: {}, // 当前购买的商品 包含多个规格
      buyItem: {}, // 当前购买的规格
      showPayPopup: false, // 权益卡选择优惠券弹窗
      payData: {}, // 支付参数
      payUrl: '', // 支付回跳链接

      activityStatus: '', // 活动状态
      goodsStatusMap: {}, // 商品对应状态
      goodsPriceStatusMap: {}, // 商品对应的价格状态
      itemStatusMap: {}, // 当前商品下多规格状态
      itemPriceStatusMap: {}, // 当前商品下多规格的价格状态
      curRule: '', // 当前规则 可能是总规则 可能是菜单规则

      topArr: [], // 若存在分组 各个分组的offset集合
      isClickScroll: false,

      killGoods: [], //秒杀商品

      hasStickyTag: false, //是否有吸顶标签
      upShow: false, //升级弹窗
      surpriseShow: false,//惊喜弹窗
      surpGoods: [],//惊喜商品
      surpList: [],
      buySurp: [],
      linkGoods: '',
      hasCoupon: true,//是否有优惠券
      isMini: false,
      cityList: [
        { id: '3', name: '福州市' },
        { id: '036652995ac5451fbb6791c3491e1c2d', name: '厦门市' },
        { id: '818447834079563776', name: '广州市' }
      ],//门店列表
      cityName: '',
      cityConfirmShow: false,
      cityChangeShow: false,
      queryCityId: '',
      unQueryCityId: ''
    }
  },
  computed: {
    // 当前菜单下的商品 tab
    curGoods() {
      let c = this.AI.partitions.subfieldsInfos ? this.AI.partitions.subfieldsInfos[this.tabIndex] : { groupInfos: [], promotionalInfo: {} }
      // 处理 卡片上 标签问题 逗号隔开的字符改为 数组
      c.groupInfos.map((group) => group.goodsInfos.map((good) => good.productTags = Array.isArray(good.productTags) ? good.productTags : good.productTags ? good.productTags.split(',') : []))
      return c
    },
    // 多规格情况下 规格的list
    itemList() {
      return this.buyGoods.itemInfos ? this.buyGoods.itemInfos : []
    },
    // 根据人群、所在城市 判断是否显示
    showWithCrow() {
      return (good) => {
        // 没有showWithCrow或者有的情况下状态可购买时显示
        return (!good.showWithCrow || (good.showWithCrow && this.goodsStatusMap[good.id] === 'CAN_BUY'))
      }
    },
  },
  async created() {
    initBack();
    await this.$getAllInfo(['userId', 'cityId'])

    const q = this.$route.query
    console.log('jjk', q);
    // 若是教练邀请 获取教练id
    this.inviteTeacherId = q.teacherId || q.inviteTeacherId
    this.inviteUserId = q.inviteUserId
    // 来源
    this.from = q.from
    this.queryCityId = q.shopCityId || this.cityId || 3// 区别于mixin里的cityId
    this.unQueryCityId = this.queryCityId
    this.cityList.map(item => {
      if (item.id == this.queryCityId) {
        this.cityName = item.name
      }
    })
    // 本次商城的id
    this.shopId = q.id || this.$route.params.id

    // 分享的链接
    const uu = encodeURIComponent(`${webUrl}`)
    this.shareParams.path = `/pages/webView/index?webUrl=${uu}`

    // 推荐弹窗  签到或评价会弹的
    this.isSignInRecommended = q.reason ? true : false

    // 获取活动总信息
    const r = await this.getActivity()
    if (r === '0') return

    // 海报的二维码
    if (this.userId) this.initQr()

    // 获取绑定关系
    this.getBindInfo()

    // 小程序卡片分享
    if (this.AI.shopStyle?.shareStatus) {
      this.miniShare()
    }
    // 是否有优惠券
    this.hasCouponFun()
    // 埋点
    if (this.appTypeStr === "mini") {
      this.isMini = true
      this.countPoint('402', '402', '40015')
    } else {
      this.countPoint('402', '402', '40014')
    }


    // 支付成功回调 小程序购买成功提示
    appPaySuccess().then(() => {
      this.paySuccessCallback()
    })
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && !this.AI.activityTrailer) {
        setTimeout(() => {
          this.getGoodStatus()
        }, 500)
      }
    })
  },
  methods: {
    newAppBack,
    previewFinish() {
      this.getActivity()
    },
    setBarHeight(h) {
      this.barHeight = h || 0
    },
    setTabIndex(v) {
      this.tabIndex = v
      this.getGoodStatus()
      // 是否有滚动
      this.setScroll()
    },
    toPath(path) {
      const p = path.split('?')[0]
      let p1 = path.split('?')[1] ? `&${path.split('?')[1]}` : ''
      if (p === 'https://web.chaolu.com.cn') {
        return
      }
      if (this.inviteTeacherId) {
        p1 = `${p1}&inviteTeacherId=${this.inviteTeacherId}`
        path = `${path}&inviteTeacherId=${this.inviteTeacherId}`
      }
      if (this.inviteUserId) {
        p1 = `${p1}&inviteUserId=${this.inviteUserId}`
        path = `${path}&inviteUserId=${this.inviteUserId}`
      }

      const hasDomain = p.indexOf('http') > -1
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${hasDomain ? `${p}${p1}` : (window.location.origin + '/#' + `${p}${p1}`)}`,
        })
      }
      if (this.appTypeStr === 'and') {
        App.intentToActivity(JSON.stringify({
          "className": ".ui.AppWebViewLaunchStandardActivity",
          "data": [
            {
              "key": "url",
              "value": path
            },
            {
              "key": "removeTitle",
              "value": "1"
            }
          ]
        }))
      }
      if (this.appTypeStr === 'ios') {
        hasDomain ? appOpenWeb('', path) : (this.$router.push(p))
      }
    },
    toAppPath(obj) {
      if (!obj) return
      if (obj.type === '21') {
        if (this.inviteTeacherId) obj.extendJson.url = `${obj.extendJson.url}&inviteTeacherId=${this.inviteTeacherId}`
        if (this.inviteUserId) obj.extendJson.url = `${obj.extendJson.url}&inviteUserId=${this.inviteUserId}`
      }
      jump(obj)
    },
    async openItemSelect() {
      console.log('openItemSelect', this.activityStatus)
      if (this.activityStatus == 'end') {
        this.$toast('活动已结束')
        return
      } else if (this.activityStatus != 'in') {
        this.$toast('活动还没开始哦')
        return
      }
      await this.getItemStatus()
      this.isItemSelectShow = true
    },
    next() {
      // 当前规格下是否包含 指定门店的情况
      const ext = this.buyItem.extendInfo
      if (ext && ext.perOnlyVenue) {
        this.venueSelectShow = true
        return
      }
      // 只要是多规格 就判断是否弹规格确认弹窗
      if (this.buyGoods.itemInfos.length > 1) {
        this.isItemConfirmShow = this.itemIndex != undefined ? true : false
      } else {
        // 实体卡的情况下单独判断
        if (this.buyGoods.goodsType === 22) {
          if (this.address.userAddressId) {
            this.itemIndex = 0
            this.buyCard(this.buyGoods)
          } else {
            this.$toast('请填写邮寄地址')
          }
        }
      }

    },
    chooseItem(i, status, item) {
      if (status !== 'CAN_BUY') return
      this.itemIndex = i
      this.buyItem = item
    },
    nextConfirm() {
      if (this.itemIndex === undefined) return

      this.isItemConfirmShow = false
      this.isItemSelectShow = false

      this.buyCard(this.buyGoods)
    },
    checkCanBuy(info) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios.post(this.baseURLApp + '/activityShopOrder/checkBuy', {
        goodsId: info.id,
        goodsItemId: this.buyItem.id,
        shopId: this.shopId,
        userId: this.userId,
      }).then((r) => {
        this.$toast.clear()
        return r.data
      }).catch(() => {
        this.$toast.clear()
        this.$toast('网络开小差了');
        return 0
      })
    },
    async buyCard(item) {
      // 未登录
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }

      // item可能来自推荐弹窗组件item.isRecommended:true
      // 推荐的商品可能不是当前分组下的商品 所以goodsStatusMap无法使用
      this.buyGoods = item
      localStorage.setItem("buyGoods", JSON.stringify(item));
      this.buyItem = this.itemIndex === undefined ? item.itemInfos[0] : item.itemInfos[this.itemIndex]
      if (this.activityStatus == 'end') {
        this.$toast('活动已结束')
        return
      } else if (this.activityStatus != 'in') {
        this.$toast('活动还没开始哦')
        return
      }

      // 如果由推荐商品的弹窗触发的 必然是能购买的
      if (!item.isRecommended && this.goodsStatusMap[item.id] !== 'CAN_BUY') {
        this.$toast('不符合购买条件')
        return
      }

      // 规格数量大于1或者为实体卡时 打开规格选项弹窗
      if (((item.itemInfos.length > 1 || [22].includes(item.goodsType)) && this.itemIndex === undefined)) {
        this.openItemSelect()
        return
      }

      // 当前规格下是否包含 指定门店的情况
      const ext = this.buyItem.extendInfo
      if (ext && ext.perOnlyVenue && this.itemIndex === undefined) {
        this.itemIndex = 0
        this.venueSelectShow = true
        return
      }

      this.itemIndex = undefined
      // 城市筛选
      if (item.goodsType == 24 || item.goodsType == 25) {
        this.cityConfirmShow = true
      } else {
        const res = await this.checkCanBuy(item)
        if (!res) {
          this.$toast('无法购买')
          return
        }
        const obj = JSON.parse(JSON.stringify(item))
        this.payAction(obj)
      }
    },
    async gogoBuy() {
      const res = await this.checkCanBuy(this.buyGoods)
      if (!res) {
        this.$toast('无法购买')
        return
      }
      const obj = JSON.parse(JSON.stringify(this.buyGoods))
      this.payAction(obj)
      this.cityConfirmShow = false
    },
    payAction(info) {
      // 如果是教练分享单个商品的情况 支付完回到页面还是只能看到单商品的弹窗 因此回调链接加上goodsId
      const goodsId = info.isRecommended ? info.id : ''
      let url = `/pages/webView/index?webUrl=${webUrl}&userId=1&goodsId=${goodsId}&shopCityId=${this.queryCityId}`
      let payData = {
        subject: this.buyItem.productName,
        extendsJson: {
          appVersion: '2.1.11',
          shopId: this.shopId,
          activityShopGoodsId: this.buyGoods.id + '',
          activityShopGoodsItemId: this.buyItem.id,
          from: this.from,
          userAddressId: this.address.userAddressId,
          perUseVenueId: this.perUseVenueId
          // inviteUserId: this.inviteId,
        },
        totalAmount: this.buyItem.salePrice + '',
        venueId: '31',
        cityId: this.queryCityId,
        goodsNum: [12, 22].includes(info.goodsType) ? '1' : undefined,
        isApp: '1',
        type: info.goodsType, // 礼品卡12  课包10 实体卡22
        userId: this.userId,
        token: this.token,
        extendsType: '4',
        mouldId: this.buyItem.productId,
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: this.appTypeStr === 'mini' ? '商品名称' : this.buyItem.productName,
      }
      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      } else {
        appPaySuccess().then(() => {
          this.paySuccessCallback()
        })
      }

      // 弹个选择优惠券的窗
      if (info.canUseVoucher) {
        this.showPayPopup = true
        this.payData = payData
        this.payUrl = url
      } else {
        appPay(payData, url);
      }
    },

    async timeFinish() {
      if (this.activityStatus == 'in') {
        this.activityStatus = 'end'
        setTimeout(() => {
          this.getGoodStatus()
        }, 500)
      } else {
        await this.getActivity()
      }
    },
    getBindInfo() {
      this.$axios
        .post(this.baseURLApp + "/userDistribute/bindUser", {
          hasWx: true,
          activityNo: this.shopId, // shopId就是老接口里的activityNo
          userId: this.userId,
          saleTeacherId: this.inviteTeacherId,
          saleUserId: this.inviteUserId
        })
        .then((res) => {
          const d = res.data;
          this.inviteUserInfo = d || {}
          this.inviteTeacherId = d && d.shareTeacherId
        });
    },

    getActivity(tabIndex = 0) {
      console.log(tabIndex, 'tabIndex')
      return this.$axios.post(`${this.baseURLApp}/activityShopWeb/getShop`, {
        cityId: this.queryCityId,
        shopId: this.shopId,
        userId: this.userId,
      }).then(res => {
        // 活动总信息
        this.AI = res.data
        if (!this.AI.activityTrailer) {
          // 设置活动状态
          this.activityStatus = this.AI.diffStartTime > 0 ? 'notin' : this.AI.diffEndTime > 0 ? 'in' : 'end'
          // 设置页面标题
          document.title = this.AI.activityName
          // 设置小程序卡片分享内容
          this.shareParams.title = this.AI.wxShareContent
          this.shareParams.miniImage = this.AI.wxShareImg || ''
          // 兜底
          if (!this.AI.shopStyle.groupTitleImg) this.AI.shopStyle.groupTitleImg = 'https://img.chaolu.com.cn/ACT/anniversary-2023/coverbg.png'
          // 预加载海报图
          if (this.AI.shareImg) {
            let img = new Image()
            img.src = this.AI.shareImg
            img.onload = () => { }
          }

          this.setTabIndex(tabIndex)
          // 获取滚动元素高度
          this.getHeight()
        }
      }).catch((res) => {
        this.$toast(res.msg)
        setTimeout(() => {
          if (this.appTypeStr === 'mini') {
            wx.miniProgram.switchTab({
              url: `/pages/home/index`
            })
          } else {
            this.newAppBack()
          }
        }, 1000)
        return res.code
      })
    },

    // 获取当前菜单tab下商品的id集合 单独获取商品的购买状态 结果用id匹配 {34: 'CAN_BUY'}
    getGoodStatus() {
      const idArr = []
      this.curGoods.groupInfos.map((group) => {
        group.goodsInfos.map((goods) => {
          idArr.push(goods.id)
        })
      })

      const params = {
        goodsId: idArr,
        shopId: this.shopId,
        userId: this.userId,
      }
      // 获取按钮状态
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsStatus`, params).then(res => {
        this.goodsStatusMap = res.data.goodsStatusMap
        this.$toast.clear()

        // 判断 分栏底下的商品 是否因为人群关系 全部隐藏 此时 需要隐藏整个分栏
        this.curGoods.groupInfos.map((group) => {
          let show = false
          group.goodsInfos.map((goods) => {
            if (!goods.showWithCrow || (goods.showWithCrow && this.goodsStatusMap[goods.id] === 'CAN_BUY')) show = true
          })
          this.$set(group, 'show', show)
        })
      })
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodMaxDiscountV2`, params).then(res => {
        this.goodsPriceStatusMap = res.data.goodsDiscountMap
      })
    },

    // 获取当前购买商品的规格id集合 结果用id匹配 {34: 'CAN_BUY'}
    getItemStatus() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      const idArr = this.buyGoods.itemInfos.map((item) => item.id)

      const params = {
        goodItems: idArr,
        goodsId: this.buyGoods.id,
        shopId: this.shopId,
        userId: this.userId,
      }
      // 规格价格
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodItemMaxDiscount`, params).then(res => {
        this.itemPriceStatusMap = res.data.goodsDiscountMap
      })
      // 规格按钮状态
      return this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsItemStatus`, params).then(res => {
        this.itemStatusMap = res.data.goodsStatusMap
        this.$toast.clear()
      })
    },

    paySuccessCallback() {
      this.$toast('支付成功')
      this.showPayPopup = false
      setTimeout(() => {
        this.perUseVenueId = ''
        this.getGoodStatus()
      }, 1000)
    },
    showShare() {
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      this.isShareShow = true
      this.countPoint('402', '402', '40012')
    },

    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: `activity_${this.shopId}`,
            id: this.shopId,
            webUrl,
          }),
        })
        .then((res) => {
          this.userDetail = res.data
        });
    },
    miniShare() {
      const uu = encodeURIComponent(`${webUrl}`)
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: `/pages/webView/index?webUrl=${uu}`,
        },
      })
    },
    openRule(isTotalRule) {
      this.curRule = isTotalRule ? this.AI.shopStyle.ruleContent : this.curGoods.saleRule
      this.isRuleShow = true
    },
    close() {
      this.isItemSelectShow = false
      this.itemIndex = undefined
    },
    getHeight() {
      this.$nextTick(() => {
        this.navHeight = this.$refs.nav ? this.$refs.nav.clientHeight : 0
      })
    },
    toGroup(el, index) {
      this.groupIndex = index
      const ref = this.$refs[el]

      this.isClickScroll = true
      setTimeout(() => {
        this.isClickScroll = false
      }, 1000)
      window.scrollTo({
        left: 0,
        top: ref[0].offsetTop - (this.barHeight + this.navHeight + 45) - 10,
        behavior: 'smooth'
      })
    },
    setScroll() {
      this.topArr = []
      setTimeout(() => {
        // 计算各个group的offsetTop
        this.curGoods.groupInfos.map((group) => {
          let top = this.$refs[`group${group.id}`][0].offsetTop - (this.barHeight + this.navHeight + 45) - 10
          this.topArr.push(top)
        })

        if (this.curGoods.showGroupMenu && this.curGoods.groupInfos.length > 1) {
          window.addEventListener('scroll', this.handleScroll, true)
        } else {
          window.removeEventListener('scroll', this.handleScroll, true)
        }
      }, 2000);
    },
    handleScroll(e) {
      if (e.target.nodeName !== '#document' || this.isClickScroll) return
      let top = document.documentElement.scrollTop || document.body.scrollTop
      for (let i = 0; i < this.topArr.length; i++) {
        if (top < this.topArr[i + 1]) {
          this.groupIndex = i
          break;
        } else {
          this.groupIndex = this.topArr.length - 1
        }
      }
    },
    hexToRgba(hex, opacity) {
      if (!hex) return ''
      return `rgba(${parseInt('0x' + hex.slice(1, 3))}, ${parseInt('0x' + hex.slice(3, 5))}, ${parseInt('0x' + hex.slice(5, 7))}, ${opacity})`
    },
    recommendedClose() {
      this.getGoodStatus()
    },
    addressChange(obj) {
      this.address = obj
    },
    //门店弹出窗口返回的数据
    choseStorePop(val, param) {
      this.venueSelectShow = false
      if (param) {
        this.isItemSelectShow = false
        this.perUseVenueId = param.venueId[0]
        this.buyCard(this.buyGoods)
      }
    },
    closeStorePop() {
      this.itemIndex = this.itemIndex === undefined ? undefined : this.itemIndex
    },
    // sticky方法
    stickyFun(p) {
      if (p.isFixed) {
        if (!this.hasStickyTag) {
          this.getHeight()
          this.hasStickyTag = true
        }
      }
    },
    // 显示优惠券
    showCouponFun() {
      let d = { cityId: this.queryCityId, shopId: this.shopId, token: this.token, userId: this.userId }
      this.$refs.couponRef.showFun(d)
      this.countPoint('402', '402', '40010')
    },
    // 是否有优惠券
    hasCouponFun() {
      let d = { cityId: this.queryCityId, shopId: this.shopId, token: this.token, userId: this.userId, page: 1, rows: 1, type: '' }
      this.$axios.post(`${this.baseURLApp}/user_voucher/queryUserAllVoucherInShop`, d).then(res => {
        this.hasCoupon = res.data.length > 0
      })
    },
    // 优惠券跳转
    goUse(link) {
      console.log(link)
      let key1 = this.AI.partitions.subfieldsInfos.findIndex((item) => item.id == link.subfieldId)
      this.setTabIndex(key1)
      let item = this.AI.partitions.subfieldsInfos.find(item => item.id == link.subfieldId);
      if (item) {
        let groupItem = item.groupInfos.find(item => item.id == link.groupId);
        this.groupIndex = item.groupInfos.findIndex(item => item.id == link.groupId)
        if (groupItem) {
          let goodsItem = groupItem.goodsInfos.find(item => item.id == link.goodsId);
          if (goodsItem) {
            setTimeout(() => {
              const targetElement = document.querySelector(`.good${goodsItem.id}`);
              const { top } = targetElement.getBoundingClientRect();
              this.linkGoods = goodsItem.id
              window.scrollTo({
                left: 0,
                top: top - (this.barHeight + this.navHeight + 45) - 10,
                behavior: 'smooth'
              })
            }, 500);
            setTimeout(() => {
              this.linkGoods = ''
            }, 5000)
          } else {
            this.$toast('匹配商品已下架')
          }
        }
      }

    },
    // 私教月卡升级
    goUp() {
      this.upShow = false
      this.$router.push({
        path: '/superdeer-activity/sell-membership/coach-upgrade',
        query: { id: this.shopId, inviteTeacherId: this.inviteTeacherId }
      })
    },
    // 关闭加购弹窗
    closeSF() {
      this.surpriseShow = false
      this.buySurp = []
    },
    // 显示修改城市弹窗
    changeCityShowFun() {
      this.cityChangeShow = true
      this.cityConfirmShow = false
    },
    // 修改城市
    changeCityFun(id) {
      this.unQueryCityId = id
    },
    cityChangeClose() {
      this.cityChangeShow = false
      this.unQueryCityId = this.queryCityId
    },
    comfrimcCityFun() {
      this.queryCityId = this.unQueryCityId
      this.cityList.map(item => {
        if (this.queryCityId == item.id) {
          this.cityName = item.name
        }
      })
      this.hasStickyTag = false
      this.cityChangeShow = false
      this.getActivity(this.tabIndex)
    },
    // 领取优惠券
    getCoupon(){
      this.$axios.post(this.baseURLApp + '/anniversary/sixth/preheat/sendAllVoucher', {
        userId: this.userId,
        token: this.token
      }).then(res => {
        this.$toast('已领取周年庆专属优惠券')
        this.hasCouponFun()
      })
    }
  },
}
</script>
<style lang="less" scoped>
.marketing-preview {
  min-height: 100vh;
}

/deep/.van-sticky--fixed {
  .tm {
    height: 70px !important;

    .ii {
      display: none;
    }
  }

  .s-add {
    position: initial !important;

  }
}

.add-box {
  /deep/.van-sticky--fixed {
    right: auto;
  }
}

/deep/.van-swipe__indicator {
  background-color: #FF5A00;
}

.marketing-template-2023 {
  min-height: 100vh;
  background: #FB7A37;
  padding-bottom: 80px;

  div {
    box-sizing: border-box;
  }

  img {
    width: 100%;
  }

  .template-content {
    width: 100%;
  }

  .background {
    position: relative;
    width: 100%;

    .head-img {
      display: block;
      min-height: 30px;
    }

    .top-button {
      position: absolute;
      right: 0;
      top: 238px;
      width: 88px;
      z-index: 99;

      .icon {
        width: 88px;
        height: 88px;
        object-fit: contain;
        margin-bottom: 32px;
      }

      .button {
        line-height: 56px;
        background: rgba(36, 40, 49, 0.3);
        border-radius: 40px 0 0 40px;
        color: #fff;
        width: 100%;
        text-align: center;
        margin-bottom: 32px;
        padding-left: 6px;
      }

      .coupon {
        position: absolute;
        width: 122px;
        transform: translateX(-34px);
      }

      // .s-add {
      //   position: fixed;
      //   left: 0;
      //   top: 410px;
      //   width: 126px;
      // }
    }


    .count-down {
      position: absolute;
      width: 100%;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .s-add {
    position: fixed;
    left: 0;
    top: 400px;
    width: 126px;
    z-index: 100;
  }

  .invite-block {
    width: 686px;
    height: 104px;
    margin: 0px 32px 30px;
    background: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_bg_invite.png);
    background-size: 100% 100%;
    padding: 0 36px;
    color: #EEEEEE;

    .invite-head {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      background: #f5f5f5;
      flex-shrink: 0;
      margin-right: 20px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }


  .banner-block {
    width: 100%;
    margin: 6px 0px 30px;
    padding: 0 32px;

    .small-banner {
      width: 333px;
    }
  }

  .kill-block {
    width: 696px;
    // height: 636px;
    background: url(https://img.chaolu.com.cn/ACT/anniversary-marketing-2024/ms-bg1.png?v=2) top/100% auto no-repeat;
    background-size: 100% auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 277px 29px 30px;

    .kill-item {
      width: 312px;
      height: 413px;
      background: #fff;
      border-radius: 12px;
      padding: 24px;
      position: relative;
      &:nth-child(n+3) {
        margin-top: 14px;
      }

      img {
        border-radius: 12px;
        width: 264px;
        height: 160px;
      }

      .line1 {
        margin-top: 20px;
        font-size: 28px;
        font-weight: 600;
      }

      .line2>span {
        color: #B12E1D;
        font-size: 20px;
        background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
        border-radius: 4px;
        margin-right: 10px;
        padding: 4px 8px;
      }

      .btn {
        width: 272px;
        height: 63px;
        margin-top: 18px;
        background: url(https://img.chaolu.com.cn/ACT/anniversary-marketing-2024/buy-btn.png?v=1) top/100% auto no-repeat;
        font-size: 22px;
        font-family: BebasNeueBold;
        color: #fff;
        padding-top: 12px;
        padding-left: 10px;

        &.nobuy {
          background: url(https://img.chaolu.com.cn/ACT/anniversary-marketing-2024/nobuy-btn.png) top/100% auto no-repeat;
        }

        &.endbtn {
          background: url(https://img.chaolu.com.cn/ACT/anniversary-marketing-2024/end-btn.png) top/100% auto no-repeat;
        }


        .num1 {
          font-size: 44px;
        }
      }

      .limit-buy {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 312px;
        height: 413px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);

        img {
          width: 144px;
          height: 144px;
        }
      }
    }
  }

  .goods-title {
    width: 427px;
    display: block;
    margin: 70px auto 12px;
  }

  .nav-title {
    width: 686px;
    margin: 70px 0 34px;
  }

  .nav {
    width: 100%;
    position: relative;
    padding: 16px 32px 8px;

    .tm {
      max-width: 335px;
      min-width: 333px;
      flex: 1;
      margin-bottom: 16px;
      background: linear-gradient(180deg, #FFFFFF 0%, #FFE9E3 100%);
      border-radius: 12px;
      height: 100px;
      padding-left: 26px;
      color: #383E43;
      position: relative;
      overflow: hidden;

      &.small {
        max-width: 218px;
        min-width: 216px;
      }

      &.active {
        color: #9B270C;
        background: linear-gradient(180deg, #FFFED8 0%, #FFE293 100%);
      }

      .ii {
        transform: scale(0.8);
        transform-origin: 0 50%;
        white-space: nowrap
      }
    }

  }

  .title-line {
    width: 100%;
    color: #fff;
    padding: 20px 32px 28px;
    position: relative;

    >img {
      width: 340px;
      position: absolute;
      left: 28px;
      bottom: 26px;
    }

    .fy {
      z-index: 10;
      text-shadow: -2px -2px 0 #F83F1A,
        2px -2px 0 #F83F1A,
        -2px 2px 0 #F83F1A,
        2px 2px 0 #F83F1A;
    }

    .arrow {
      width: 21px;
    }

    .ss {
      margin-right: 4px;
    }
  }

  .triangle {
    width: 21px;
    margin-left: 10px;
  }

  .end {
    text-align: center;
    color: #fff;
    position: relative;
    margin-top: 40px;

    &::after,

    &::before {
      content: '';
      vertical-align: middle;
      display: inline-block;
      width: 60px;
      height: 1px;
      background: #fff;
    }

    &::after {
      margin: -2px 0 0 29px;
    }

    &::before {
      margin: -2px 29px 0 0;
    }
  }

  .group-bar {
    width: 750px;
    padding: 10px 32px 10px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.2);
    margin: -8px 0 36px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .group-bg {
      width: 686px;
      display: flex;
      padding: 8px;
      background-color: #FFB956;
      border-radius: 20px;
    }

    .item {
      width: 20%;
      color: #fff;
      border-radius: 20px;
      padding: 12px 0;

      &.active {
        background: linear-gradient(0deg, #FFFFFF 0%, #FFE9E3 100%);
        color: #F9624E;
      }
    }
  }

  .good-block {
    width: 100%;
    padding: 0 32px;

    .ad-group {
      .ad-item {
        width: 100%;
        margin: 0 0 32px;
      }
    }

    .good-group {
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.2);
      padding: 24px;
      margin-bottom: 40px;
      min-height: 280px;
      position: relative;

      &.has-title {
        padding: 74px 24px 24px;
      }

      &.only-img {
        background: none;
        padding-left: 0;
        padding-right: 0;
      }

      .two-img {
        width: 50%;
      }

      .three-img {
        width: 33.33%;
      }
    }

    .group-name {
      position: absolute;
      top: -10px;
      left: 0;
      height: 60px;
      width: 50%;
      background-size: 100% 100%;
      color: #68413C;
      padding: 0 0 0 24px;
      overflow: hidden;
    }
  }

  .pop-rule {
    border-radius: 24px 24px 0 0;
    color: #242831;

    .pop-rule-title {
      height: 140px;
      text-align: center;
      line-height: 130px;
      position: relative;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(246, 103, 67, 0.3) 100%);

      .pop-rule-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
      }
    }

    .pop-rule-content {
      padding: 0 52px;
      max-height: 600px;
      overflow: auto;
      padding-bottom: 60px;
      white-space: pre-line;
    }
  }

  .pop-item {
    border-radius: 24px 24px 0 0;
    color: #242831;

    .buy-title {
      border-bottom: 1px solid #EEEEEE;
      height: 97px;
      line-height: 97px;
      padding: 0 32px;
      text-align: center;
      position: relative;
    }

    .buy-close {
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);

    }

    .cbox {
      padding: 37px 32px 0;
      border-bottom: 1px solid #eee
    }

    .buy-tips {
      color: #9AA1A9;
      margin: 4px 0 22px;
    }

    .goods-line {
      display: flex;
      margin: 22px 0 60px;

      .goods-img {
        width: 256px;

        img {
          width: 100%;
          border-radius: 16px;
          display: block;
        }
      }

      .goods-info {
        flex: 1;
        margin-left: 30px;

        .goods-name {
          color: #242831;
        }

        .time {
          color: #242831;
          margin: 12px 0 20px;
          line-height: 1;
        }

        .labels {
          margin: 0 0 15px;

          .label {
            height: 30px;
            background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
            padding: 0 13px;
            margin: 0 12px 0 0;
            color: #B12E1D;
            border-radius: 4px;
          }
        }

        .price-line {
          margin-top: 8px;
          line-height: 1;

          .dl {
            color: #EE4832;
            margin-right: 4px;
          }

          .price {
            color: #EE4832;
            margin-right: 9px;
            position: relative;
            top: 2px;
          }

          .ori-price {
            text-decoration: line-through;
            color: #6C727A;
          }
        }
      }
    }

    .city-list {
      padding: 0 0 32px;

      .city-model {
        margin-top: 24px;
        height: 128px;
        background: #F5F5F5;
        border-radius: 8px;
        padding: 0 32px;

        &.disabled {
          color: #9AA1A9;
        }

        &.active {
          border: 4px solid #242831;

          .checkbox {
            width: 48px;
            height: 48px;
            background: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring2023/check.png);
            background-size: 100% 100%;
            border: none;
            margin: 0;
          }
        }

        .checkbox {
          width: 32px;
          height: 32px;
          border: 1px solid #DDD;
          border-radius: 50%;
          margin-right: 8px;
        }

        .has-buy {
          width: 102px;
        }
      }


    }

    .next {
      line-height: 96px;
      background: #FFDE00;
      border-radius: 8px;
      margin: 34px 32px 0;
      text-align: center;
      margin-bottom: 34px;

      &.grey {
        filter: grayscale(1);
      }
    }
  }

  .pop-sure {
    width: 590px;
    border-radius: 12px;
    padding: 50px 40px;

    .sure-title {
      color: #242831;
      text-align: center;
    }

    .sure-content {
      color: #3C454E;
      line-height: 1.6;
      margin: 36px 0 48px;
      text-align: center;

      span {
        color: #F03C18;
      }
    }

    .sure-sub {
      border-radius: 8px;
      background: #FFDE00;
      color: #000;
      line-height: 96px;
      text-align: center;
    }
  }

  .pop-up {
    background: url(https://img.chaolu.com.cn/ACT/anniversary-marketing-2024/up-bg.png) top/100% auto no-repeat;
    width: 610px;
    height: 721px;
    padding: 581px 35px 44px;

    .btn {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        width: 260px;
        height: 96px;
        line-height: 96px;
        color: #fff;
        font-size: 36px;
        text-align: center;
        border-radius: 100px;
        background-color: #FFA16E;

        &:last-child {
          background-color: #FF5A00;
        }
      }

    }
  }

  .pop-surprise {
    background-color: transparent;
    overflow: visible;

    .s-box {
      width: 630px;
      height: 725px;
      position: relative;

      .close2 {
        width: 60px;
        height: 60px;
        position: absolute;
        right: 10px;
        top: 47px;
        z-index: 100;
      }

      .s-item {
        width: 610px;
        height: 725px;
        position: relative;
        margin: 0 auto;

        &.gift {
          background: url(https://img.chaolu.com.cn/ACT/anniversary-marketing-2024/gift-add.png) top/100% auto no-repeat;
        }

        &.pri {
          background: url(https://img.chaolu.com.cn/ACT/anniversary-marketing-2024/pri-add.png?v=1) top/100% auto no-repeat;
        }

        .btn {
          width: 550px;
          height: 96px;
          line-height: 96px;
          text-align: center;
          background-color: #FFDE00;
          border-radius: 8px;
          font-size: 32px;
          font-weight: 600;
          position: absolute;
          bottom: 56px;
          left: 30px;
        }
      }

    }
  }

  .pop-city-c {
    overflow: visible;
    padding-top: 200px;
    border-radius: 24px 24px 0px 0px;

    .icon {
      width: 273px;
      height: 260px;
      position: absolute;
      top: -100px;
      left: 50%;
      transform: translateX(-50%);
    }

    .tips {
      padding: 0px 100px 110px;
      font-size: 28px;
      line-height: 2;
      text-align: center;

      span {
        color: #F03C18;
        font-size: 36px;
      }
    }

    .btn {
      padding: 26px 32px;
      text-align: center;
      line-height: 92px;
      height: 92px;
      font-size: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
      box-sizing: content-box;

      >div:first-child {
        border: 1px solid #EEEEEE;
        border-radius: 8px;
        width: 212px;
      }

      >div:last-child {
        background-color: #FFDE00;
        border: 1px solid #FFDE00;
        border-radius: 8px;
        width: 442px;
        font-weight: 600;
      }
    }
  }

  .pop-change {
    border-radius: 24px 24px 0px 0px;

    .p-title {
      font-size: 28px;
      line-height: 96px;
      height: 98px;
      border-bottom: 1px solid #EEEEEE;
      text-align: center;
      font-weight: 600;
    }

    .city-list {
      padding: 35px 32px 78px;

      p {
        padding: 0px 33px;
        font-size: 32px;
        background-color: #F5F5F5;
        border-radius: 8px;
        height: 128px;
        line-height: 128px;

        &.act {
          border: 2px solid #232831;
        }
      }

      p+p {
        margin-top: 32px;
      }
    }

    .btn {
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
      box-sizing: content-box;
      padding: 27px 32px;
      text-align: center;

      >p:first-child {
        font-size: 22px;
        color: #68413C;
        margin-bottom: 20px;
      }

      >p:last-child {
        background-color: #FFDE00;
        border: 1px solid #FFDE00;
        border-radius: 8px;
        height: 92px;
        font-weight: 600;
        line-height: 92px;
      }
    }
  }


  .hand {
    // border: 15px solid red;
    animation: wobble-hor-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s infinite alternate both;

    &::after {
      content: '';
      width: 89px;
      height: 96px;
      background: url(https://img.chaolu.com.cn/ACT/anniversary-marketing-2024/hand.png?v=2) no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: -20px;
      bottom: -70px;
      z-index: 50;
      animation: count-down 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s infinite alternate both;
    }

    @keyframes count-down {
      0% {
        transform: scale(1);
        -webkit-transform: scale(1);
      }

      100% {
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
      }
    }

    @-webkit-keyframes wobble-hor-bottom {

      0%,
      100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
      }

      15% {
        -webkit-transform: translateX(-15px) rotate(-3deg);
        transform: translateX(-15px) rotate(-3deg);
      }

      30% {
        -webkit-transform: translateX(7px) rotate(3deg);
        transform: translateX(7px) rotate(3deg);
      }

      45% {
        -webkit-transform: translateX(-7px) rotate(-1.8deg);
        transform: translateX(-7px) rotate(-1.8deg);
      }

      60% {
        -webkit-transform: translateX(4px) rotate(1.2deg);
        transform: translateX(4px) rotate(1.2deg);
      }

      75% {
        -webkit-transform: translateX(-3px) rotate(-0.6deg);
        transform: translateX(-3px) rotate(-0.6deg);
      }
    }

    @keyframes wobble-hor-bottom {

      0%,
      100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
      }

      15% {
        -webkit-transform: translateX(-15px) rotate(-3deg);
        transform: translateX(-15px) rotate(-3deg);
      }

      30% {
        -webkit-transform: translateX(7px) rotate(3deg);
        transform: translateX(7px) rotate(3deg);
      }

      45% {
        -webkit-transform: translateX(-7px) rotate(-1.8deg);
        transform: translateX(-7px) rotate(-1.8deg);
      }

      60% {
        -webkit-transform: translateX(4px) rotate(1.2deg);
        transform: translateX(4px) rotate(1.2deg);
      }

      75% {
        -webkit-transform: translateX(-3px) rotate(-0.6deg);
        transform: translateX(-3px) rotate(-0.6deg);
      }
    }

  }

  @bei: 1px;

  .canvascss {
    position: fixed;
    bottom: -200%;
    // top: 0;
    width: @bei*600;
    height: @bei*1066;
    margin: 0 auto;
    box-sizing: border-box;

    .canvascss_bg {
      width: 100%;
      height: 100%;
    }



    .canvascss_left {
      position: absolute;
      left: @bei*26;
      bottom: @bei*30;
      z-index: 1;
      width: @bei*100;
      height: @bei*100;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .canvascss_name {
      position: absolute;
      left: @bei*150;
      bottom: @bei*110;
      z-index: 1;
      color: #fff;
      width: @bei*252;
    }

    .canvascss_right {
      position: absolute;
      right: @bei*34;
      bottom: @bei*28;
      width: @bei*124;
      height: @bei*124;
      overflow: hidden;
      //background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/border.png);
      background-size: 100% 100%;
      padding: @bei*12;

      img {
        border-radius: @bei*8;
        width: 100%;
        height: 100%;
      }
    }

  }

  .red {
    color: #EE4832;
  }

  /deep/.canvas-box {
    padding: 0 40px !important;
    box-sizing: border-box;

    .create-img {
      width: 100% !important;
    }
  }
}
</style>
